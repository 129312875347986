export const restartModelVideo = modelPlane => {
  if (modelPlane.material?.diffuseTexture?.video?.currentTime) {
    modelPlane.material.diffuseTexture.video.currentTime = 0;
    modelPlane.material.diffuseTexture.video.muted = false;
  }
};

export const muteModelVideo = modelPlane => {
  if (modelPlane.material?.diffuseTexture?.video?.muted === false) {
    modelPlane.material.diffuseTexture.video.muted = true;
  }
};

export const unmuteModelVideo = modelPlane => {
  if (modelPlane.material?.diffuseTexture?.video?.muted === true) {
    modelPlane.material.diffuseTexture.video.muted = false;

    if (modelPlane.material.diffuseTexture.video.paused) {
      modelPlane.material.diffuseTexture.video.play();
    }
  }
};

export const mutePauseModelVideo = modelPlane => {
  if (modelPlane.material?.diffuseTexture?.video?.muted === false) {
    modelPlane.material.diffuseTexture.video.muted = true;

    if (!modelPlane.material.diffuseTexture.video.paused) {
      modelPlane.material.diffuseTexture.video.pause();
    }
  }
};
