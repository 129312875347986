import React from 'react';
import classNames from 'classnames';

import styles from './View.module.css';
import Video from './Video';

const View = ({
  isPlaying,
  isMuted,
  activeCamera,
  cameraName,
  cameraIndex,
  canvasRef,
  onClick,
  timeRef,
}) => {
  const isHidden = activeCamera && activeCamera !== cameraName;
  const isThumbnail = !activeCamera;

  return (
    <button
      className={classNames(styles.view, {
        [styles.thumbnail]: isThumbnail,
        [styles.large]: !isThumbnail && !isHidden,
        [styles.isHidden]: isHidden,
      })}
      onClick={e => {
        onClick();
        e.stopPropagation();
      }}
    >
      <Video
        cameraIndex={cameraIndex}
        isLarge={!isThumbnail && !isHidden}
        isMuted={isMuted}
        isPlaying={isPlaying}
        isVisible={!isHidden}
        timeRef={timeRef}
      />
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <canvas ref={canvasRef} className={styles.canvas} />
    </button>
  );
};

export default View;
