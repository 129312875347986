import React from 'react';
import { Helmet } from 'react-helmet';

const defaultMeta = [
  {
    name: 'author',
    content: 'Random Studio',
  },
  {
    name: 'keywords',
    content:
      'Royal Academy of Fine Arts Antwerp, wwwshowww, Fashion Department',
  },
  { name: 'theme-color', content: '#ffffff' },
  { name: 'msapplication-TileColor', content: '#ffffff' },
];

const SEO = () => {
  return (
    <Helmet
      title="WWWSHOWWW"
      meta={[
        ...defaultMeta,
        {
          name: 'description',
          content:
            'Royal Academy of Fine Arts Antwerp | Fashion Department | wwwshowww',
        },

        // OG
        {
          property: 'og:title',
          content:
            'Royal Academy of Fine Arts Antwerp | Fashion Department | wwwshowww',
        },
        { property: 'og:site_name', content: 'wwwshowww' },
        {
          property: 'og:description',
          content:
            'On Saturday June 27th at 8PM, the Fashion Department of the Royal Academy of Fine Arts Antwerp will take you on a journey through our dynamic digital platform',
        },
        { property: 'og:type', content: 'website' },
        { property: 'og:locale', content: 'en_US' },
        { property: 'og:url', content: 'https://www.showww.be/' },
        { property: 'og:image', content: '/img/AFD-Show-OG.jpg' },

        // Explicit image sizing for twitter
        { property: 'og:image:width', content: 500 },
        { property: 'og:image:height', content: 500 },

        // Twitter
        {
          name: 'twitter:title',
          content:
            'Royal Academy of Fine Arts Antwerp | Fashion Department | wwwshowww',
        },
        {
          name: 'twitter:description',
          content:
            'On Saturday June 27th at 8PM, the Fashion Department of the Royal Academy of Fine Arts Antwerp will take you on a journey through our dynamic digital platform',
        },
        { name: 'twitter:image', content: '/img/AFD-Show-OG.jpg' },
        { name: 'twitter:card', content: 'summary_large_image' },
        {
          name: 'twitter:site',
          content:
            'Royal Academy of Fine Arts Antwerp | Fashion Department | wwwshowww',
        },
      ]}
    >
      >
      <html lang="en" />
      <base target="_blank" href="/" />
      <link
        rel="apple-touch-icon"
        sizes="57x57"
        href="/favicons/apple-icon-57x57.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="60x60"
        href="/favicons/apple-icon-60x60.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="72x72"
        href="/favicons/apple-icon-72x72.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="76x76"
        href="/favicons/apple-icon-76x76.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href="/favicons/apple-icon-114x114.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href="/favicons/apple-icon-120x120.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href="/favicons/apple-icon-144x144.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href="/favicons/apple-icon-152x152.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/favicons/apple-icon-180x180.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href="/favicons/android-icon-192x192.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicons/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href="/favicons/favicon-96x96.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicons/favicon-16x16.png"
      />
    </Helmet>
  );
};

export default SEO;
