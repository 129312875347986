import { Vector3 } from '@babylonjs/core';

const rotationsByMeshName = {
  'Clone of Sculpture01_Matte_Color.glb': {
    back: {
      position: new Vector3(110, -50, -5),
      rotationZ: Math.PI * -0.6,
    },
    front: {
      position: new Vector3(110, -50, 5),
      rotationZ: Math.PI * 0.6,
    },
  },
  'Clone of Sculpture02_Matte_Color.glb': {
    back: {
      position: new Vector3(110, -50, -5),
      rotationZ: Math.PI * -0.55,
    },
    front: {
      position: new Vector3(110, -50, 5),
      rotationZ: Math.PI * 0.55,
    },
  },
  'Clone of Sculpture03_Matte_Color.glb': {
    back: {
      position: new Vector3(110, -50, -5),
      rotationZ: Math.PI * -0.5,
    },
    front: {
      position: new Vector3(110, -50, 5),
      rotationZ: Math.PI * 0.5,
    },
  },
  'Clone of Sculpture04_Matte_Color.glb': {
    back: {
      position: new Vector3(90, 120, -5),
      rotationZ: -0.15 * Math.PI,
    },
    front: {
      position: new Vector3(90, 120, 5),
      rotationZ: 0.15 * Math.PI,
    },
  },
  'Clone of Sculpture05_Matte_Color.glb': {
    back: {
      position: new Vector3(110, -50, -15),
      rotationZ: Math.PI * -0.55,
    },
    front: {
      position: new Vector3(110, -50, 15),
      rotationZ: Math.PI * 0.55,
    },
  },
  'Clone of Sculpture06_Matte_Color.glb': {
    back: {
      position: new Vector3(90, 55, -5),
      rotationZ: -0.15 * Math.PI,
    },
    front: {
      position: new Vector3(90, 60, 5),
      rotationZ: 0.15 * Math.PI,
    },
  },
  'Clone of Sculpture07_Matte_Color.glb': {
    back: {
      position: new Vector3(110, -20, -5),
      rotationZ: Math.PI * -0.6,
    },
    front: {
      position: new Vector3(110, -50, 5),
      rotationZ: Math.PI * 0.6,
    },
  },
  'Clone of Sculpture08_Matte_Color.glb': {
    back: {
      position: new Vector3(110, -50, -5),
      rotationZ: Math.PI * -0.6,
    },
    front: {
      position: new Vector3(110, -50, 5),
      rotationZ: Math.PI * 0.6,
    },
  },
  'Clone of Sculpture09_Matte_Color.glb': {
    back: {
      position: new Vector3(110, -50, -5),
      rotationZ: Math.PI * -0.6,
    },
    front: {
      position: new Vector3(110, -50, -5),
      rotationZ: Math.PI * 0.6,
    },
  },

  'Clone of Sculpture10_Matte_Color.glb': {
    back: {
      position: new Vector3(110, -50, -5),
      rotationZ: Math.PI * -0.6,
    },
    front: {
      position: new Vector3(110, -50, 5),
      rotationZ: Math.PI * 0.6,
    },
  },

  'Clone of 2ndYearSculpture01.glb': {
    back: {
      position: new Vector3(0, 80, -40),
      rotationZ: 0,
      rotationX: 0,
    },
    front: {
      position: new Vector3(0, 55, 40),
      rotationZ: 0,
      rotationX: 0,
    },
  },

  'Clone of 2ndYearSculpture02.glb': {
    back: {
      position: new Vector3(0, 80, -40),
      rotationZ: 0,
      rotationX: 0,
    },
    front: {
      position: new Vector3(0, 55, 40),
      rotationZ: 0,
      rotationX: 0,
    },
  },
  'Clone of 3rdYearSculpture01.glb': {
    back: {
      position: new Vector3(-60, -35, -75),
      rotationZ: 0,
      rotationX: -Math.PI * 0.5,
    },
    front: {
      position: new Vector3(-50, 35, -95),
      rotationZ: 0,
      rotationX: Math.PI * 0.5,
    },
  },
  'Clone of KAROLINA.glb': {
    back: {
      position: new Vector3(0, 105, -25),
      rotationZ: 0,
      rotationX: 0,
    },
    front: {
      position: new Vector3(0, 75, 45),
      rotationZ: 0,
      rotationX: 0,
    },
  },
  'Clone of MARIE.glb': {
    back: {
      position: new Vector3(0, 105, -25),
      rotationZ: 0,
      rotationX: 0,
    },
    front: {
      position: new Vector3(0, 75, 45),
      rotationZ: 0,
      rotationX: 0,
    },
  },
  'Clone of SABRINA.glb': {
    back: {
      position: new Vector3(0, 105, -25),
      rotationZ: 0,
      rotationX: 0,
    },
    front: {
      position: new Vector3(0, 75, 45),
      rotationZ: 0,
      rotationX: 0,
    },
  },
  'Clone of ISABELLE.glb': {
    back: {
      position: new Vector3(0, 105, -25),
      rotationZ: 0,
      rotationX: 0,
    },
    front: {
      position: new Vector3(0, 75, 45),
      rotationZ: 0,
      rotationX: 0,
    },
  },
  'Clone of FLORENTINA.glb': {
    back: {
      position: new Vector3(0, 105, -25),
      rotationZ: 0,
      rotationX: 0,
    },
    front: {
      position: new Vector3(0, 75, 45),
      rotationZ: 0,
      rotationX: 0,
    },
  },
  'Clone of GHOST.glb': {
    back: {
      position: new Vector3(0, 100, -25),
      rotationZ: -Math.PI * 0.0054,
      rotationX: 0,
    },
    front: {
      position: new Vector3(0, 75, 25),
      rotationZ: Math.PI * 0.0075,
      rotationX: 0,
    },
  },
};

export default rotationsByMeshName;

// 'Clone of GHOST.glb': {
//   back: {
//     position: new Vector3(0, 0, -25),
//     rotationZ: Math.PI * 0.05,
//     rotationX: 0,
//   },
//   front: {
//     position: new Vector3(25, 0, 25),
//     // rotationZ: Math.PI * 0.0089,
//     rotationZ: Math.PI * 0,
//     // rotationZ: 0,
//     rotationX: 0,
//   },
// },
