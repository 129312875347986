import React from 'react';
import styled from 'styled-components';
const ConvertMillisecondsToTimeString = ({ ms }) => {
  let seconds = Math.round(ms / 1000);
  let days = Math.floor(seconds / (3600 * 24));
  let hours = Math.floor((seconds / 3600) % 24);
  let minutes = Math.floor(((seconds - hours * 3600) / 60) % 60);
  seconds = (seconds - hours * 3600 - minutes * 60) % 60;

  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }
  return (
    <>
      <StyledTimeBox>{days}</StyledTimeBox>:
      <StyledTimeBox>{hours}</StyledTimeBox>:
      <StyledTimeBox>{minutes}</StyledTimeBox>:
      <StyledTimeBox>{seconds}</StyledTimeBox>
    </>
  );
};

export default ConvertMillisecondsToTimeString;

// days + ":" + hours + ":" + minutes + ":" + seconds

const StyledTimeBox = styled.div`
  display: inline-block;
  width: 115px;
  @media (max-width: 960px) {
    width: 70px;
  }
`;
