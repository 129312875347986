import React, { useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import styled from 'styled-components';

const Newsletter = ({ signupRef }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleNameChange = e => setName(e.target.value);
  const handleEmailChange = e => setEmail(e.target.value);

  const handleSubmit = async e => {
    e.preventDefault();

    setError('');
    addToMailchimp(email, {
      FNAME: name,
    })
      .then(data => {
        if (data.result !== 'error') {
          setIsSuccess(true);
        } else {
          console.log('went to err');
          setError(true);
        }
        console.log('data:', data);
      })
      .catch(error => {
        // Errors in here are client side
        // Mailchimp always returns a 200
        console.log('Client side error');
        setError(true);
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit} aria-label="subscribe form">
        {isSuccess ? (
          <>
            <StyledMessageContainer>
              <StyledSuccess>
                WWWELCOME! Thank you for signing up.
              </StyledSuccess>
              <StyledTick alt="success" src="/img/icons/check.svg" />
            </StyledMessageContainer>
          </>
        ) : (
          <div>
            <StyledCallToAction>
              <StyledSpan />
              Notify me
            </StyledCallToAction>
            <StyledInput
              aria-label="input name"
              placeholder="Enter Your Name"
              value={name}
              type="text"
              onChange={handleNameChange}
              ref={signupRef}
            />
            <StyledInput
              aria-label="input email"
              placeholder="Enter Your Email"
              value={email}
              type="text"
              onChange={handleEmailChange}
            />
            <StyledLegalContainer>
              <StyledLegal>
                This email address will only be used by the Royal Academy of
                Fine Arts Antwerp to share information about our Fashion
                Department.
              </StyledLegal>
            </StyledLegalContainer>
            <StyledButton alt="submit" type="submit">
              Submit
            </StyledButton>

            {error && (
              <StyledMessageContainer>
                <StyledSuccess>Something went wrong. Try again.</StyledSuccess>
              </StyledMessageContainer>
            )}
          </div>
        )}
      </form>
    </>
  );
};

export default Newsletter;

const StyledSpan = styled.span`
  display: inline-block;
  width: 300px;
  @media (max-width: 900px) {
    width: 117px;
  }
`;

const StyledCallToAction = styled.p`
  margin-top: 40px;
  font-size: 44px;
  text-align: left;

  @media (max-width: 900px) {
    font-size: 24px;
    margin-top: 60px;
  }
`;

const StyledInput = styled.input`
  cursor: input;
  height: 64px;
  font-size: 44px;
  display: block;
  min-width: 224px;
  width: 370px;
  background-color: transparent;
  border: none;
  padding: 0 0 3px;
  outline: none;
  border-bottom: 1px solid black;
  border-radius: 0;
  margin-top: 23px;
  @media (max-width: 960px) {
    width: 100%;
    font-size: 24px;
    height: 34px;
  }
`;
const StyledButton = styled.button`
  cursor: pointer;
  font-size: 44px;
  display: block;
  padding: 0;
  margin-top: 25px;

  background-color: transparent;
  border: none;
  color: red;
  text-decoration: underline;
  @media (max-width: 960px) {
    font-size: 24px;
  }
`;

const StyledLegalContainer = styled.div`
  max-width: 290px;
  text-align: left;
  @media (max-width: 960px) {
    width: 100%;
    max-width: 100%;
  }
`;
const StyledLegal = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  line-height: 12px;
  margin-top: 10px;
  letter-spacing: -0.2px;
  text-transform: uppercase;
  color: #0f0f0f;

  mix-blend-mode: normal;
  opacity: 0.5;

  @media (max-width: 960px) {
    font-family: Helvetica;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 12px;

    text-transform: uppercase;
  }
`;

const StyledMessageContainer = styled.div`
  margin: 20px auto 0;
  width: 33%;
`;
const StyledSuccess = styled.p`
  display: inline-block;
  font-size: 44px;
  line-height: 46px;
  @media (max-width: 960px) {
    font-size: 24px;
    line-height: 26px;
  }
`;

const StyledTick = styled.img`
  display: inline-block;
  width: 40px;
  margin-top: 20px;
  @media (max-width: 960px) {
    margin-top: 10px;
    width: 20px;
  }
`;
