import React from 'react';
import styles from './Loading.module.css';

export default ({ progress, onPressEnter }) => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  return (
    <div className={styles.loading}>
      {isSafari ? (
        <>
          <span>
            To best experience WWWSHOWWW, use Firefox or Chrome
            <br />
            or watch via the live stream
          </span>
          <a
            href="https://vimeo.com/433136654/6a74edbd67"
            className={styles.enter}
          >
            Live stream
          </a>
        </>
      ) : progress < 1 ? (
        <>
          <span>Loading WWWSHOWWW</span>
          <span className={styles.bar}>
            <span
              className={styles.progress}
              style={{ transform: `scaleX(${progress})` }}
            />
          </span>
        </>
      ) : (
        <>
          <span>
            To best experience WWWSHOWWW, turn on your audio
            <br />
            Viewing on a computer is recommended
          </span>
          <button onClick={onPressEnter} className={styles.enter}>
            Enter
          </button>
          <span className={styles.fallback}>
            {'If you experience any issues, '}
            <a href="https://vimeo.com/433136654/6a74edbd67">
              watch via the live stream
            </a>
            
          </span>
        </>
      )}
    </div>
  );
};
