export default timeline => {
  let totalDuration = 0;
  let scenes = [];
  for (const scene of timeline) {
    scenes.push({
      ...scene,
      startTime: totalDuration,
    });
    totalDuration += scene.duration;
  }

  return { scenes, totalDuration };
};
