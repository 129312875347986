import React, { useRef } from 'react';
import styled from 'styled-components';
import ConvertMillisecondsToTimeString from './ConvertMillisecondsToTimeString';
import Newsletter from '../components/Newsletter';
import useWindowSize from '../utils/useWindowSize';
import RafaaLogo from '../../static/img/RAFAA_logo.png';
import useTimeAfterShowStart from '../utils/useTimeAfterShowStart';

const HoldingPage = () => {
  const timeAfterShowStart = useTimeAfterShowStart();
  const timeUntilShowStart = timeAfterShowStart * -1;
  const signupRef = useRef();

  const { width } = useWindowSize();
  return (
    <StyledContainer>
      <StyledHeader>
        <StyledTitle>
          <StyledBold>Royal Academy of Fine Arts Antwerp </StyledBold> <br />
          Fashion Department
        </StyledTitle>
        <StyledHeaderDetails>
          {width > 900 ? (
            <StyledSmallTime>
              <StyledBold>June 27 2020 </StyledBold> <br />
              8PM CET
            </StyledSmallTime>
          ) : (
            <StyledSmallTime>
              <StyledBold>27.06.2020</StyledBold> <br />
              8PM CET
            </StyledSmallTime>
          )}

          <StyledSmallSignUp onClick={() => signupRef.current.focus()}>
            Sign Up
          </StyledSmallSignUp>
        </StyledHeaderDetails>
      </StyledHeader>
      <StyledTimerContainer>
        <StyledTimer>
          <ConvertMillisecondsToTimeString ms={timeUntilShowStart} />
        </StyledTimer>
        <StyledMainDate>Live Show:</StyledMainDate>
        <StyledMainDate>June 27 2020 8PM CET</StyledMainDate>
      </StyledTimerContainer>
      <div>
        {width > 900 ? (
          <StyledVideoTag
            src="/video/desktop/AP-Desktop.mp4"
            loop={true}
            muted={true}
            autoPlay={true}
            playsInline
          />
        ) : (
          <StyledVideoTag
            loop={true}
            muted={true}
            autoPlay={true}
            playsInline
            src="/video/mobile/AP-Mobile.mp4"
          ></StyledVideoTag>
        )}
      </div>
      <StyledCopyContainer>
        <StyledCopy>
          Welcome to the countdown of the WWWSHOWWW. <br /> <br />
          <StyledSpan />
          On Saturday June 27th at 8PM, the Fashion Department of the Royal
          Academy of Fine Arts Antwerp will take you on a journey through our
          dynamic digital platform. These virtual front-row seats will offer you
          a unique view on the end-of-year and graduate collections of our 122
          Bachelors and 9 Masters.
          <br />
          <StyledSpan />
          Enter your email and we’ll notify you when the site launches. Lean
          back and get carried away in an exciting evening filled program full
          of surprises. <br /> <br />
          <StyledSociaMediaContainer>
            Follow Us <br />
            <StyledInstagramLink href="https://www.instagram.com/antwerpfashionofficial">
              Instagram
            </StyledInstagramLink>{' '}
            <br />
            <StyledInstagramLink href="https://www.instagram.com/explore/tags/wwwshowww/">
              #wwwshowww
            </StyledInstagramLink>
          </StyledSociaMediaContainer>
        </StyledCopy>
      </StyledCopyContainer>
      <StyledFormContainer>
        <Newsletter signupRef={signupRef} />
      </StyledFormContainer>
      <StyledFooter>
        <div className="logos">
          <a href="https://www.ap-arts.be/academie">
            <StyledSchoolLogo
              src={RafaaLogo}
              alt="Royal Academy of Fine Arts Antwerp"
            />
          </a>
        </div>
        <StyledSmallDateContainer>
          {width > 900 ? (
            <>
              <StyledSmallDate>
                <StyledBold>June 27 2020</StyledBold>
              </StyledSmallDate>
              <StyledSmallTimeBottom>8PM CET</StyledSmallTimeBottom>
            </>
          ) : (
            <>
              <StyledSmallDate>
                <StyledBold>27.06.2020</StyledBold>
              </StyledSmallDate>
              <StyledSmallTimeBottom>8PM CET</StyledSmallTimeBottom>
            </>
          )}
        </StyledSmallDateContainer>
      </StyledFooter>
    </StyledContainer>
  );
};
export default HoldingPage;

const StyledContainer = styled.div`
  width: 100%;
`;
const StyledBold = styled.span`
  font-weight: bold;
`;

const StyledTimerContainer = styled.div`
  margin-top: 89px;
  @media (max-width: 900px) {
    margin-top: 111px;
  }
`;

const StyledTimer = styled.h1`
  font-size: 134px;
  line-height: 117px;
  letter-spacing: -3.35px;
  font-family: 'Tusker Grotesk';
  font-weight: 1;
  @media (max-width: 900px) {
    font-size: 87px;
    line-height: 77px;
    letter-spacing: -2.144px;
  }
`;

const StyledMainDate = styled.p`
  font-size: 44px;
  font-family: Helvetica;
  font-size: 44px;
  line-height: 46px;

  text-align: center;
  letter-spacing: -0.93617px;
  @media (max-width: 900px) {
    font-size: 24px;
    font-family: Helvetica;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 26px;
  }
`;

const StyledHeader = styled.div`
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 38px 50px 0 22px;
  @media (max-width: 900px) {
    margin: 20px 10px 0 20px;
  }
`;

const StyledVideoTag = styled.video`
  width: 100%;
`;

const StyledCopyContainer = styled.div`
  max-width: 1040px;
  margin: 52px 0 44px 20px;

  @media (max-width: 900px) {
    margin: 63px 20px 0 20px;
  }
`;

const StyledSpan = styled.span`
  display: inline-block;
  width: 300px;
  @media (max-width: 900px) {
    width: 117px;
  }
`;

const StyledCopy = styled.div`
  font-size: 44px;
  text-align: left;
  line-height: 46px;

  @media (max-width: 900px) {
    font-size: 24px;
    line-height: 26px;
  }
`;

const StyledSociaMediaContainer = styled.div`
  margin-left: 300px;
  @media (max-width: 900px) {
    margin-left: 117px;
  }
`;
const StyledInstagramLink = styled.a`
  color: #f4360e;
`;

const StyledTitle = styled.div`
  font-size: 14px;
  text-align: left;
`;
const StyledSmallTime = styled.div`
  align-self: flex-end;
  font-size: 14px;
  margin: 0 36px 0 0;
  text-align: left;
  @media (max-width: 900px) {
    margin: 0;
    text-align: right;
  }
`;
const StyledSmallSignUp = styled.div`
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
  @media (max-width: 900px) {
    display: none;
  }
`;
const StyledHeaderDetails = styled.div`
  display: flex;
`;

const StyledFormContainer = styled.div`
  margin: 0 20px;
`;
const StyledFooter = styled.div`
  margin-top: 230px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 900px) {
    flex-direction: column;
    margin-top: 150px;
  }
`;

const StyledSchoolLogo = styled.img`
  width: 150px;
`;

const StyledSmallDateContainer = styled.div`
  margin: 0 74px 12px 0;
  align-self: flex-end;
  font-size: 14px;
  @media (max-width: 900px) {
    align-self: flex-start;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }
`;

const StyledSmallDate = styled.p`
  width: fit-content;
`;
const StyledSmallTimeBottom = styled.p`
  width: fit-content;
  align-self: flex-end;
`;
