import { useEffect, useState } from 'react';

import showStartingTime from './showStartingTime';
const startTimestamp = showStartingTime.getTime();

export default () => {
  const [timeAfterShowStart, setTimeAfterShowStart] = useState(null);

  useEffect(() => {
    let requestId;
    const tick = () => {
      const nowTimestamp = new Date().getTime();
      setTimeAfterShowStart(nowTimestamp - startTimestamp);
      requestId = requestAnimationFrame(tick);
    };

    tick();

    return () => {
      cancelAnimationFrame(requestId);
    };
  }, []);

  return timeAfterShowStart;
};
