import React, {
  useRef,
  useEffect,
  useLayoutEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import classNames from 'classnames';
import { Vector3, Quaternion } from '@babylonjs/core';
import { navigate } from '@reach/router';

import Credits from './Credits';
import Loading from './Loading';
import InterfaceText from './InterfaceText';
import ProgressBar from './ProgressBar/ProgressBar';
import View from './View';

import initializeBabylon from '../../utils/initializeBabylon';
import { createModel, loadModel } from '../../utils/babylonGenerator';
import { lerp, clamp } from '../../utils/mathUtils';
import useRunEveryFrame from '../../utils/useRunEveryFrame';
import { getCameraModelFocus } from '../../utils/showUtils';

import styles from './Show.module.css';

import showStartingTime from '../../utils/showStartingTime';

import {
  restartModelVideo,
  muteModelVideo,
  unmuteModelVideo,
  mutePauseModelVideo,
} from '../../utils/babylonModelVideoControls';

const isSafari =
  typeof window !== 'undefined' &&
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const showStartingTimeStamp = showStartingTime.getTime();

const cameras = [
  'A__Front___Static',
  // 'B__Front___Follow',
  'C__Back___Follow',
  // 'D__45___Static',
  'E__Detail___Tilt',
  'F__45___Follow',
];

const mocapFileNames = {
  a: [
    'Sculpture01_Matte_Color.glb',
    'Sculpture02_Matte_Color.glb',
    'Sculpture03_Matte_Color.glb',
    'Sculpture04_Matte_Color.glb',
    'Sculpture05_Matte_Color.glb',
    'Sculpture06_Matte_Color.glb',
    'Sculpture07_Matte_Color.glb',
    'Sculpture08_Matte_Color.glb',
    'Sculpture09_Matte_Color.glb',
    'Sculpture10_Matte_Color.glb',
  ],
  // B 10x front assets
  // collections 2 variations of spikes
  // TODO ADD 2nd type of model
  b: ['2ndYearSculpture01.glb', '2ndYearSculpture02.glb'],
  // first year modles with assets as textures
  // collection - strand beast
  c: ['3rdYearSculpture01.glb'],
  d: ['MARIE.glb'],
  e: ['FLORENTINA.glb'],
  f: ['SABRINA.glb'],
  g: ['ISABELLE.glb'],
  h: ['KAROLINA.glb'],
  // Willy
  // JULIA
  // NICO
  // SILVIA
  // ANNMARIE
  z: ['GHOST.glb'],
};

const ShowPage = ({
  activeCamera,
  isLive,
  parsedTimeline,
  isShowingCredits,
}) => {
  const [babylonState, setBabylonState] = useState();
  const [isMuted, setIsMuted] = useState(isSafari);
  const [isModelAudioPlaying, setIsModelAudioPlaying] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const [hasPressedEnter, setHasPressedEnter] = useState(false);

  const [activeModels, setActiveModels] = useState([]);
  const [mocapModels, setMocapModels] = useState([]);
  const [mocapModelsLoadingProgress, setMocapModelsLoadingProgress] = useState(
    0,
  );

  const [isCuratedViewOn, setIsCuratedViewOn] = useState(false);
  const [isRandomCuratedViewOn, setIsRandomCuratedViewOn] = useState(false);

  const cameraCanvasRefs = useRef(cameras.map(useRef));

  const timeRef = useRef(0);
  const [currentScene, setCurrentScene] = useState(parsedTimeline.scenes[0]);

  const isReadyForShow =
    babylonState && Object.entries(mocapModels).length && hasPressedEnter;

  const setTime = useMemo(() => {
    const setTimeLocal = newTime => {
      timeRef.current = newTime;

      if (
        !currentScene ||
        timeRef.current < currentScene.startTime ||
        timeRef.current > currentScene.startTime + currentScene.duration
      ) {
        const currentScene = parsedTimeline.scenes.find(
          scene => scene.startTime + scene.duration >= timeRef.current,
        );
        // Reset to after interviews (first scene) when show ends
        if (!currentScene) {
          setTimeLocal(parsedTimeline.scenes[0].duration);
          return;
        }
        setCurrentScene(currentScene);
      }
    };
    return setTimeLocal;
  }, [parsedTimeline, currentScene]);

  const runClock = useMemo(() => {
    const startTime = performance.now();
    const startTimeRefCurrent = timeRef.current;

    return () => {
      if (isLive) {
        setTime(
          (new Date().getTime() -
            (showStartingTimeStamp -
              parsedTimeline.scenes[0].duration * 1000)) /
            1000,
        );
        return;
      }
      if (isPlaying) {
        setTime(startTimeRefCurrent + (performance.now() - startTime) / 1000);
      }
    };
  }, [isPlaying, isLive, timeRef, parsedTimeline, setTime]);
  useRunEveryFrame(runClock);

  const calculatePosition = useCallback(
    (progress, model) => {
      const totalPoints = babylonState.runwayPoints.length;
      const isOnRunway = progress > 0 && progress < 1;

      if (!isOnRunway) {
        if (!model.wasDisabled) {
          model.wasDisabled = true;
          model.mesh.setEnabled(false);
          model.frontPlane.setEnabled(false);
          model.backPlane.setEnabled(false);
          model.animation.stop();

          model.mesh.alwaysSelectAsActiveMesh = false;
          model.frontPlane.alwaysSelectAsActiveMesh = false;
          model.backPlane.alwaysSelectAsActiveMesh = false;
        }

        return;
      } else {
        if (model.wasDisabled) {
          model.wasDisabled = false;
          model.animation.start(true, 1);
          model.mesh.setEnabled(true);
          model.frontPlane.setEnabled(true);
          model.backPlane.setEnabled(true);

          model.mesh.alwaysSelectAsActiveMesh = true;
          model.frontPlane.alwaysSelectAsActiveMesh = true;
          model.backPlane.alwaysSelectAsActiveMesh = true;
        }
      }

      // Calculate position
      const currentPoint = totalPoints * progress;

      const previousPointIndex = Math.floor(currentPoint);
      const previousPoint = babylonState.runwayPoints[previousPointIndex];
      const nextPoint = babylonState.runwayPoints[previousPointIndex + 1];

      if (!nextPoint) {
        if (!model.wasDisabled) {
          model.wasDisabled = true;
          model.animation.stop();
          model.mesh.setEnabled(false);
          model.frontPlane.setEnabled(false);
          model.backPlane.setEnabled(false);

          model.mesh.alwaysSelectAsActiveMesh = true;
          model.frontPlane.alwaysSelectAsActiveMesh = true;
          model.backPlane.alwaysSelectAsActiveMesh = true;
        }

        return { model, currentPoint };
      }

      const progressBetweenPoints = currentPoint % 1;

      model.mesh.position.x = lerp(
        previousPoint.x,
        nextPoint.x,
        progressBetweenPoints,
      );

      model.mesh.position.y = 0;
      model.mesh.position.z = lerp(
        previousPoint.z,
        nextPoint.z,
        progressBetweenPoints,
      );

      const pointBeforePreviousPointIndex = Math.max(0, previousPointIndex - 1);
      const pointBeforePreviousPoint =
        babylonState.runwayPoints[pointBeforePreviousPointIndex];

      const angleBetweenPreviousPoints = Math.atan(
        previousPoint.z - pointBeforePreviousPoint.z,
        previousPoint.x - pointBeforePreviousPoint.x,
      );

      const angleBetweenPoints = Math.atan(
        nextPoint.z - previousPoint.z,
        nextPoint.x - previousPoint.x,
      );

      const newAngle = lerp(
        angleBetweenPreviousPoints,
        angleBetweenPoints,
        progressBetweenPoints,
      );

      // Have the model face the right runway direction
      model.mesh.rotationQuaternion = new Quaternion.RotationAxis(
        new Vector3(0, 1, 0),
        -newAngle * 2 + Math.PI / 2,
      );

      model.skeleton.prepare();

      return { model, currentPoint, progress: currentPoint / totalPoints };
    },
    [babylonState],
  );

  // Initialize babylon & load mocap models
  useEffect(() => {
    let engine;
    const initialize = async () => {
      const initializedBabylonState = await initializeBabylon();
      engine = initializedBabylonState.engine;
      setBabylonState(initializedBabylonState);

      initializedBabylonState.scene.freezeActiveMeshes();

      const totalMocaps = Object.values(mocapFileNames).flat().length;
      let loadedMocaps = 0;

      const mocaps = await Promise.all(
        Object.entries(mocapFileNames).map(([key, fileNames]) =>
          Promise.all(
            fileNames.map(fileName => {
              return loadModel(initializedBabylonState.scene, fileName).then(
                templates => {
                  loadedMocaps += 1;
                  setMocapModelsLoadingProgress(loadedMocaps / totalMocaps);
                  return templates;
                },
              );
            }),
          ).then(templates => [key, templates]),
        ),
      );

      const templatesByKey = Object.fromEntries(mocaps);
      setMocapModels(templatesByKey);
    };

    initialize();

    return () => {
      if (engine) {
        engine.dispose();
      }
    };
  }, []);

  useEffect(() => {
    if (!isReadyForShow) {
      return;
    }
    babylonState.engine.runRenderLoop(() => {
      babylonState.scene.render();
    });
  }, [isReadyForShow, babylonState]);

  // Update which cameras are rendering
  useLayoutEffect(() => {
    if (!isReadyForShow) {
      return;
    }
    const currentCameraCanvasRefs = cameraCanvasRefs.current;
    const { engine, scene } = babylonState;

    currentCameraCanvasRefs.forEach((cameraCanvasRef, cameraIndex) => {
      if (!activeCamera || cameras[cameraIndex] === activeCamera) {
        scene.cameras[cameraIndex].attachControl(cameraCanvasRef.current, true);

        engine.registerView(
          cameraCanvasRef.current,
          scene.cameras[cameraIndex],
        );
      }
    });

    return () => {
      currentCameraCanvasRefs.forEach(cameraCanvasRef => {
        engine.unRegisterView(cameraCanvasRef.current);
      });
    };
  }, [isReadyForShow, babylonState, activeCamera, hasPressedEnter]);

  useEffect(() => {
    if (!isReadyForShow) {
      return;
    }

    let hasGoneStale = false;
    let newActiveModels = [];

    // Load all models for this scene in sequence in a huge chain of .then()s
    const megaPromiseChain = currentScene.models.reduce(
      (accPromise, modelDefinition, index) =>
        accPromise.then(
          () =>
            new Promise(resolve => {
              // Put requestAnimationFrames inbetween each to not have the browser lock up
              requestAnimationFrame(() => {
                if (hasGoneStale) {
                  resolve();
                  return;
                }
                // checks which currentscene corresponds to which mocap models
                const templatesOfCorrectType =
                  mocapModels[currentScene.modelInfo.type];
                // a
                const amountOfModelsOfSameTypeShownBeforeCurrentScene = parsedTimeline.scenes
                  .filter(
                    (scene, sceneIndex) =>
                      scene.modelInfo.type === currentScene.modelInfo.type &&
                      sceneIndex < parsedTimeline.scenes.indexOf(currentScene),
                  )
                  .reduce((acc, scene) => acc + (scene.models || []).length, 0);

                const correctIndex =
                  (amountOfModelsOfSameTypeShownBeforeCurrentScene + index) %
                  templatesOfCorrectType.length;

                const model = createModel({
                  isTextured: currentScene.modelInfo.isTextured,
                  scene: babylonState.scene,
                  modelDefinition,
                  template: templatesOfCorrectType[correctIndex],
                  templateType: currentScene.modelInfo.type,
                });

                if (!window.model) {
                  window.model = model;
                }

                newActiveModels = newActiveModels.concat([model]);
                setActiveModels(newActiveModels);
                resolve();
              });
            }),
        ),
      Promise.resolve(),
    );

    megaPromiseChain.then(() => {
      console.log('loaded full scene');
    });

    return () => {
      hasGoneStale = true;
      newActiveModels.forEach(activeModel => {
        if (activeModel.meshMaterial?.dispose) {
          activeModel.meshMaterial.dispose(true, true);
        }

        activeModel.mesh.dispose();
        activeModel.animation.dispose();
        const doNotRecurse = false;
        const disposeMaterialAndTexture = true;
        activeModel.frontPlane.dispose(doNotRecurse, disposeMaterialAndTexture);
        activeModel.backPlane.dispose(doNotRecurse, disposeMaterialAndTexture);
        activeModel.skeleton.dispose();
      });
      setActiveModels([]);
    };
  }, [isReadyForShow, babylonState, currentScene, mocapModels, parsedTimeline]);

  const updateModelsAndCameras = useCallback(() => {
    if (!isReadyForShow || !currentScene) {
      return;
    }

    if (currentScene.title === 'Interviews') return;

    const preciseCurrentTimeInScene = timeRef.current - currentScene.startTime;
    const firstTurn = 1 / 3 + 0.035;
    const secondTurn = 2 / 3 - 0.035;

    let isAudioModelOnMainline = false;

    const curatedViewChecker = {
      mainlineCount: 0,

      thirdYearCollectionModelOnRunwayFirstPart: false,
      thirdYearCollectionModelOnRunwayLastPart: false,
      hasModelsOnRunway: false,
      isOverviewCamera: false,
    };

    const isSecondYearCollection =
      currentScene.title.includes('SECOND YEAR BACHELOR') &&
      !currentScene.internalTitle;
    const isThirdYearCostumes =
      currentScene.title.includes('THIRD YEAR BACHELOR') &&
      !!currentScene.internalTitle;
    const isThirdYearCollection =
      currentScene.title.includes('THIRD YEAR BACHELOR') &&
      !currentScene.internalTitle;

    // Move the models and return it for camera movements
    const updatedModelsPositionAndProgress = activeModels
      .map((model, index) => {
        const modelDuration = model.modelDefinition.duration;

        const oneThirdRunway = firstTurn;
        const firstLineProgress = clamp(
          0,
          1,
          (preciseCurrentTimeInScene - model.modelDefinition.startTime) /
            modelDuration[0],
        );

        const secondLineProgress = clamp(
          0,
          1,
          (preciseCurrentTimeInScene -
            model.modelDefinition.startTime -
            modelDuration[0]) /
            modelDuration[1],
        );

        const thirdLineProgress = clamp(
          0,
          1,
          (preciseCurrentTimeInScene -
            model.modelDefinition.startTime -
            modelDuration[0] -
            modelDuration[1]) /
            modelDuration[2],
        );

        let progress = 0;
        progress += firstLineProgress * oneThirdRunway;
        progress += secondLineProgress * oneThirdRunway;
        progress += thirdLineProgress * oneThirdRunway;

        let animationSpeedRatio = 1;

        if (progress <= firstTurn) {
          animationSpeedRatio = 1 * (10 / modelDuration[0]);
        } else if (progress >= firstTurn && progress <= secondTurn) {
          animationSpeedRatio = clamp(0, 1, 1 * (10 / modelDuration[1]));

          //
          if (!isAudioModelOnMainline && model.modelDefinition.hasAudio) {
            isAudioModelOnMainline = true;
          }

          // Specific progress point to check to restart the video if available
          if (
            progress >= firstTurn &&
            progress <= firstTurn + 0.01 &&
            model.modelDefinition.hasAudio
          ) {
            restartModelVideo(model.frontPlane);
            restartModelVideo(model.backPlane);
          }

          // Special logic for 2nd year clsoe walking models
          // If 3rd model of 4 total in 2nd year collection is past 85% of mainline
          if (
            isSecondYearCollection &&
            secondLineProgress >= 0.55 &&
            index === 2
          ) {
            curatedViewChecker.isOverviewCamera = true;
          }

          //
          if (isThirdYearCollection) {
            if (secondLineProgress <= 0.1) {
              curatedViewChecker.thirdYearCollectionModelOnRunwayFirstPart = true;
            } else {
              curatedViewChecker.thirdYearCollectionModelOnRunwayLastPart = true;
            }
          }

          curatedViewChecker.mainlineCount++;
        } else if (progress >= secondTurn) {
          // If 3rd model of 4 total in 2nd year collection is in exit line
          if (isSecondYearCollection && index === 2) {
            curatedViewChecker.isOverviewCamera = true;
          }

          animationSpeedRatio = 1 * (10 / modelDuration[2]);
        }

        //
        if (model.animation.speedRatio !== animationSpeedRatio) {
          model.animation.speedRatio = animationSpeedRatio;
        }

        //

        // Audio logic
        if (
          // 1. On mainline and not paused
          isAudioModelOnMainline &&
          model.modelDefinition.hasAudio &&
          isPlaying &&
          !isMuted
        ) {
          if (isModelAudioPlaying) {
            unmuteModelVideo(model.frontPlane);
            unmuteModelVideo(model.backPlane);
          }
        } else if (
          // 2. On mainline and paused - Mute asset and pause
          isAudioModelOnMainline &&
          model.modelDefinition.hasAudio &&
          !isPlaying
        ) {
          if (isModelAudioPlaying) {
            mutePauseModelVideo(model.frontPlane);
            mutePauseModelVideo(model.backPlane);
          }
        } else if (
          // 3. Not on mainline but has audio - will only run if it is not muted
          (!isAudioModelOnMainline || isMuted) &&
          model.modelDefinition.hasAudio
        ) {
          muteModelVideo(model.frontPlane);
          muteModelVideo(model.backPlane);
        }

        if (!curatedViewChecker.hasModelsOnRunway && progress > 0) {
          curatedViewChecker.hasModelsOnRunway = true;
        }

        return calculatePosition(progress, model);
      })
      .filter(model => model !== undefined);

    if (isAudioModelOnMainline) {
      setIsModelAudioPlaying(true);
    } else if (isModelAudioPlaying) {
      setIsModelAudioPlaying(false);
    }

    // First on is video
    if (isCuratedViewOn) {
      if (isThirdYearCollection) {
        if (curatedViewChecker.thirdYearCollectionModelOnRunwayFirstPart) {
          navigate(`/?camera=${cameras[0]}`);
        } else if (
          curatedViewChecker.thirdYearCollectionModelOnRunwayLastPart
        ) {
          navigate(`/?camera=${cameras[1]}`);
        } else {
          navigate(`/?camera=${cameras[3]}`);
        }
      } else if (isThirdYearCostumes && curatedViewChecker.mainlineCount > 0) {
        navigate(`/?camera=${cameras[3]}`);
      }
      // Special logic for 2nd years close walking models
      else if (isSecondYearCollection && curatedViewChecker.isOverviewCamera) {
        navigate(`/?camera=${cameras[3]}`);
      } else if (curatedViewChecker.mainlineCount > 0) {
        navigate(`/?camera=${cameras[0]}`);
      } else if (curatedViewChecker.hasModelsOnRunway) {
        navigate(`/?camera=${cameras[3]}`);
      } else {
        navigate(`/`);
      }
    }

    //
    babylonState.scene.cameras.forEach(camera => {
      let cameraTargetX = camera.position.x;
      let cameraTargetZ = camera.position.z;

      switch (camera.name) {
        case cameras[1]:
          // Back follow -
          const closestModel = updatedModelsPositionAndProgress.reduce(
            (a, b) =>
              getCameraModelFocus(
                a,
                b,
                firstTurn + 0.05,
                secondTurn - 0.05,
                false,
              ),
            undefined,
          );

          if (closestModel && closestModel.model && closestModel.progress) {
            cameraTargetX = closestModel.model.mesh.position.x - 36;
            cameraTargetZ = closestModel.model.mesh.position.z;
          }
          break;

        case cameras[2]:
          // E_DETAIL___TILT - Return the furthers progressed model along the mainline
          const furthestPanModel = updatedModelsPositionAndProgress.reduce(
            (a, b) => getCameraModelFocus(a, b, firstTurn, secondTurn, true),
            undefined,
          );

          if (
            furthestPanModel &&
            furthestPanModel.model &&
            furthestPanModel.progress
          ) {
            cameraTargetX = furthestPanModel.model.mesh.position.x + 50;
            cameraTargetZ = furthestPanModel.model.mesh.position.z;
          }

          const currentRotation = camera.rotation;
          if (
            furthestPanModel &&
            furthestPanModel.model &&
            furthestPanModel.progress
          ) {
            const relativeRotationProgress =
              (furthestPanModel.progress - firstTurn) / firstTurn;
            const panProgress = -0.09 * relativeRotationProgress + 0.023;

            currentRotation.x = panProgress;
          } else {
            const defaultXRotation = -0.025;
            const newXTarget = lerp(
              currentRotation.x,
              defaultXRotation.x,
              0.01,
            );
            currentRotation.x = newXTarget;
          }

          break;
        default:
          break;
      }

      const cameraSnappiness = 0.1;
      camera.position.x = lerp(
        camera.position.x,
        cameraTargetX,
        cameraSnappiness,
      );
      camera.position.z = lerp(
        camera.position.z,
        cameraTargetZ,
        cameraSnappiness,
      );
    });
  }, [
    calculatePosition,
    isReadyForShow,
    activeModels,
    babylonState,
    currentScene,
    isMuted,
    isPlaying,
    isModelAudioPlaying,
    isCuratedViewOn,
    isRandomCuratedViewOn,
  ]);

  useRunEveryFrame(updateModelsAndCameras);

  useEffect(() => {
    if (!isPlaying) {
      activeModels.forEach(model => {
        if (model.animation.isPlaying) {
          model.animation.stop();
        }
      });

      return;
    }
    activeModels.forEach(model => {
      if (!model.animation.isPlaying && !model.wasDisabled) {
        model.animation.start(true, 1);
      }
    });
  }, [activeModels, isPlaying]);

  if (!babylonState || !Object.entries(mocapModels).length) {
    return <Loading progress={mocapModelsLoadingProgress} />;
  }

  if (!isReadyForShow) {
    return (
      <Loading
        progress={mocapModelsLoadingProgress}
        onPressEnter={() => setHasPressedEnter(true)}
      />
    );
  }

  return (
    <div className={styles.wrapper}>
      <div
        className={classNames(styles.scene, {
          [styles.grid]: !activeCamera,
        })}
      >
        {cameras.map((cameraName, cameraIndex) => (
          <View
            isPlaying={isPlaying}
            isMuted={isMuted || isModelAudioPlaying}
            timeline={parsedTimeline}
            key={cameraName}
            activeCamera={activeCamera}
            cameraName={cameraName}
            cameraIndex={cameraIndex}
            canvasRef={cameraCanvasRefs.current[cameraIndex]}
            onClick={() => {
              if (isCuratedViewOn) {
                setIsCuratedViewOn(false);
              }
              navigate(activeCamera ? '/' : `/?camera=${cameraName}`);
            }}
            timeRef={timeRef}
          />
        ))}
      </div>
      {isShowingCredits && <Credits />}
      <InterfaceText
        scene={currentScene}
        isMuted={isMuted}
        isCuratedViewOn={isCuratedViewOn}
        isShowingCredits={isShowingCredits}
        onClickShow={() => navigate('/')}
        onClickCredits={() => navigate('/?view=credits')}
        onToggleMute={() => setIsMuted(wasMuted => !wasMuted)}
        onToggleCuratedView={isCurated => setIsCuratedViewOn(isCurated)}
      />
      {!isLive && !isShowingCredits && (
        <ProgressBar
          totalDuration={parsedTimeline.totalDuration}
          onUpdateIsPlaying={setIsPlaying}
          onUpdateTime={setTime}
          timeRef={timeRef}
          timeline={parsedTimeline}
        />
      )}
    </div>
  );
};

export default ShowPage;
