import React, { useState } from 'react';

import addToMailchimp from 'gatsby-plugin-mailchimp';

import Tick from '../../../static/img/icons/check.svg';

import styles from './Credits.module.css';

const Credits = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const handleEmailChange = e => setEmail(e.target.value);
  const handleSubmit = async e => {
    e.preventDefault();

    setError('');
    addToMailchimp(email)
      .then(data => {
        if (data.result !== 'error') {
          setIsSuccess(true);
        } else {
          console.log('went to err');
          setError(true);
        }
        console.log('data:', data);
      })
      .catch(error => {
        // Errors in here are client side
        // Mailchimp always returns a 200
        console.log('Client side error');
        setError(true);
      });
  };
  return (
    <div className={styles.credits}>
      <h1 className={styles.title}>WWWSHOWWW</h1>
      <div className={styles.subtitle}>
        <span className={styles.smallText}>CREATED BY </span>
        <span className={styles.biggerText}>
          THE FASHION DEPARTMENT OF THE ROYAL ACADEMY OF THE FINE ARTS ANTWERP
        </span>
        <span className={styles.smallText}> and </span>
        <span className={styles.biggerText}> random studio</span>
      </div>
      <div className={styles.logoContainer}>
        <div className={styles.logo}>
          <a href="https://random.studio/">
            <img src="/img/RandomStudio.svg" alt="Random Studio" />
          </a>
        </div>
        <div className={styles.logo}>
          <a href="https://www.ap-arts.be/en/academy">
            <img
              src="/img/logo1.svg"
              alt="Royal Academy of Fine Arts Antwerp"
            />
          </a>
        </div>
        <div className={styles.logo}>
          <a href="https://www.antwerpen.be/">
            <img
              src="/img/logo2.svg"
              alt="Royal Academy of Fine Arts Antwerp"
            />
          </a>
        </div>
        <div className={styles.logo}>
          <a href="https://dehandelsbeurs.be/">
            <img src="/img/logo3.svg" alt="De Handels Beurs Amtwerpen" />
          </a>
        </div>
        <div className={styles.logo}>
          <a href="https://antwerp-fashion.be/">
            <img
              src="/img/logo4.svg"
              alt="Royal Academy of Fine Arts Antwerp"
            />
          </a>
        </div>
        <div className={styles.logo}>
          <a href="https://www.woolmark.com/">
            <img src="/img/logo5.png" alt="Woolmark" />
          </a>
        </div>
        <div className={styles.logo}>
          <a href="https://www.suedwollegroup.com/">
            <img src="/img/logo6.png" alt="Suedwolle Group" />
          </a>
        </div>
        <div className={styles.logo}>
          <a href="http://shoottheartist.com/">
            <img src="/img/logo7.png" alt="Shoot the Artist" />
          </a>
        </div>
      </div>
      <div className={styles.textContainer}>
        <div className={styles.textGroup}>
          <span className={styles.nextLine}>
            <span className={styles.boldText}>show start</span> 20:00:00 CEST
          </span>
          <span className={styles.nextLine}>
            <span className={styles.boldText}>
              FIRST YEAR BACHELOR - SKIRTS, DRESSES, JACKETS
            </span>
            20:01:00 CEST
          </span>
          <span className={styles.nextLine}>
            <span className={styles.boldText}>
              SECOND YEAR BACHELOR - EXERCISE HISTORICAL COSTUME
            </span>
            20:28:00 CEST
          </span>
          <span className={styles.nextLine}>
            <span className={styles.boldText}>
              SECOND YEAR BACHELOR - COLLECTIONS
            </span>
            20:37:00 CEST
          </span>
          <span className={styles.nextLine}>
            <span className={styles.boldText}>
              THIRD YEAR BACHELOR - EXERCISE WORLD COSTUME{' '}
            </span>
            21:00:00 CEST
          </span>
          <span className={styles.nextLine}>
            <span className={styles.boldText}>
              THIRD YEAR BACHELOR - COLLECTIONS
            </span>
            21:05:00 CEST
          </span>
          <span className={styles.nextLine}>
            <span className={styles.boldText}>MASTER STUDENTS</span>21:28:00
            CEST
          </span>
          <span className={styles.nextLine}>
            <span className={styles.boldText}>MASTER SHOWCASE 2020</span>
            22:35:00 CEST
          </span>
        </div>
        <div className={styles.textGroup}>
          <span className={styles.boldText}>RANDOM STUDIO</span>
          <span className={styles.smallText}>roles</span>
          CREATIVE DIRECTION ART DIRECTION 3D STYLIST VIRTUAL SET DESIGN
          SOFTWARE DEVELOPMENT INTERFACE DESIGN{' '}
          <span className={styles.nextLine}>
            <span className={styles.smallText}>WITH</span> POIUYTRE –– CREATIVE
            DIRECTION{' '}
          </span>
        </div>

        <div className={styles.textGroup}>
          <span className={styles.boldText}>
            FASHION DEPARTMENT<span className={styles.smallText}>OF THE</span>
            ROYAL ACADEMY OF FINE ARTS ANTWERP
          </span>
          <span className={styles.smallText}>ART DIRECTION</span>
          WALTER VAN BEIRENDONCK{' '}
          <span className={styles.smallText}>COORDINATION</span> MANON KUNDIG,
          MAUREEN DE CLERCQ <span className={styles.smallText}>MUSIC</span> ONE
          TRACK BRAIN/ JEROEN DE PESSEMIER
          <span className={styles.smallText}>PRESS & COMMUNICATION</span>
          MIA PRCE, DAVID FLAMÉE
          <span className={styles.smallText}>COPYWRITING</span>
          DOMINIQUE NZEYIMANA
          <span className={styles.smallText}>BUSINESS MANAGEMENT</span>
          GABINA DE PAEPE
          <span className={styles.smallText}>OFFICE MANAGEMENT</span>
          ALEXANDER LAMBRECHTSEN, RITA MOONEN, MIEKE VAN DEN BROEK
        </div>
        <div className={styles.textGroup}>
          <span className={styles.smallText}> CAMPAIGN PHOTOGRAPHY</span>
          WILLY VANDERPERRE
          <span className={styles.smallText}> CAMPAIGN STYLING</span>
          OLIVIER RIZZO
          <span className={styles.smallText}> CAMPAIGN CASTING DIRECTOR</span>
          SAMUEL ELLIS SCHEINMAN FOR DMCASTING
          <span className={styles.smallText}> CAMPAIGN GRAPHIC DESIGN</span>
          PAUL BOUDENS
          <span className={styles.smallText}> CAMPAIGN MODELS</span>
          DAAN DUEZ @REBEL MANAGEMENT, MAORO BULTHEEL @REBEL MANAGEMENT, KAIA
          GERBER @DNA MODELS, CRAIG SHIMIRIMANA @REBEL MANAGEMENT, KIKI WILLEMS
          @DNA MODELS, ILONA DESMET @GIRL MGNT, SORA CHOI @FORD PARIS, MICA
          ARGAÑARAZ @DNA MODELS, ANOK YAI @NEXT
          <span className={styles.smallText}> SPECIAL THANKS TO</span>
          STÉPHANE VIRLOGEUX, LIEZE RUBBRECHT FOR 4OCTOBER
        </div>

        <div className={styles.textGroup}>
          <span className={styles.smallText}>
            ALL MUSIC WRITTEN, PLAYED, PRODUCED & MIXED BY
          </span>
          ONE TRACK BRAIN (JEROEN DE PESSEMIER)
          <br />
          <span className={styles.smallText}>EXCEPT </span>
          <br />
          "I DON'T DO FASHION" <span className={styles.smallText}>BY</span> ONE
          TRACK BRAIN FEAT SYLVIE KREUSCH
          <br />
          "MODAL" "BATS" "THE ANSWER TO EVERYTHING"{' '}
          <span className={styles.smallText}>BY</span> AGGBOROUGH
          <br />
          <span className={styles.smallText}>MASTER SHOW:</span>
          <br />
          "TOUCH OF DEW" <span className={styles.smallText}>BY</span> ONE TRACK
          BRAIN
          <br />
          "RAIN"
          <span className={styles.smallText}>BY</span> JORAM FEITSMA
          <br />
          "AWOL MARINE" <span className={styles.smallText}>BY</span> PERFUME
          GENIUS
          <br />
          "HYMN" <span className={styles.smallText}>BY</span> CRAIG ARMSTRONG
          <br />
          "OMG WE CRUEL" <span className={styles.smallText}>BY</span> FELIX
          MACHTELINCKX
          <br />
          "NINE CANDLES IN A CREEK" <span className={styles.smallText}>
            BY
          </span>{' '}
          ONE TRACK BRAIN
          <br />
          "QUEEN" <span className={styles.smallText}>BY</span> PERFUME GENIUS
        </div>

        <div className={styles.textGroup}>
          <span className={styles.smallText}>
            DIRECTION AND DOP MASTERSHOW 2020
          </span>
          BJORN TAGEMÖSE
          <span className={styles.smallText}>COORDINATION MASTERSHOW 2020</span>
          KATARINA VANDENBOSSCHE, GABINA DE PAEPE
          <span className={styles.smallText}>PRODUCTION MASTERSHOW 2020</span>
          SHOOTTHEARTIST, KATARINA VERCAMMEN
          <span className={styles.smallText}>
            POST-PRODUCTION MASTERSHOW 2020
          </span>
          HIGH FLYING BIRDS
          <span className={styles.smallText}>FOCUS PULLER MASTERSHOW 2020</span>
          ALEXANDER BEYNE
          <span className={styles.smallText}>
            LIGHT TECHNICIAN MASTERSHOW 2020
          </span>
          DIEGO SMETS
          <span className={styles.smallText}>
            DOLLY TECHNICIAN MASTERSHOW 2020
          </span>
          JELLE ECTOR, CHRISTOPHE CAEYERS
          <span className={styles.smallText}>CASTING MASTERSHOW 2020</span>
          LIEVE GERRITS
          <span className={styles.smallText}>MODELS MASTERSHOW 2020</span>
          MANON, LORE, DEDE, FLORENCE, ZHANNA, MARGERITA, SEPPE, TIM, TYAS,
          MAURICE, JOSSE, MAXIM
          <span className={styles.smallText}>
            GRAPHIC DESIGN MASTERSHOW 2020
          </span>
          PAUL BOUDENS
          <span className={styles.smallText}>MUSIC MASTERSHOW 2020</span>
          RAIN - JORAM FEITSMA, AWOL MARINE - PERFUME GENIUS, HYMN - CRAIG
          ARMSTRONG, OMG WE CRUEL - FELIX MACHTELINCKX, NINE CANDLES IN A CREEK
          - ONE TRACK BRAIN, QUEEN - PERFUME GENIUS
          <span className={styles.smallText}>SPECIAL THANKS TO</span>
          DE HANDELSBEURS, LITES, EUROGRIP
        </div>
        <div className={styles.textGroup}>
          <span className={styles.smallText}>
            MA BLIND DATE VIDEOS PRODUCTION & DIRECTION
          </span>
          DAVID FLAMÉE
          <span className={styles.smallText}>MA BLIND DATE VIDEOS EDITING</span>
          MONICA HO
          <span className={styles.smallText}>
            MA BLIND DATE VIDEOS MODERATION
          </span>
          DOMINIQUE NZEYIMANA
          <span className={styles.smallText}>SPECIAL THANKS TO</span>
          TIM BLANKS, ALBER ELBAZ, CRAIG GREEN, DEMNA GVASALIA, STEPHEN JONES,
          CHRISTIAN LACROIX, SUSANNA LAU, SARA MAINO, GLENN MARTENS, PENNY
          MARTIN, SUZY MENKES, ROISIN MURPHY, DIANE PERNET, OLIVIER RIZZO,
          SIMONE ROCHA, RAF SIMONS, DRIES VAN NOTEN, MILOU VAN ROSSUM
        </div>
        <div className={styles.textGroup}>
          <span className={styles.smallText}>GRADUATE VIDEOS PRODUCTION</span>
          DAVID FLAMÉE
          <span className={styles.smallText}>GRADUATE VIDEOS EDITING</span>
          MONICA HO
          <span className={styles.smallText}>GRADUATE VIDEOS COPYWRITING</span>
          DOMINIQUE NZEYIMANA
          <span className={styles.smallText}>SPECIAL THANKS TO</span>
          DIRK BIKKEMBERGS, SANDER BOS, RUSHEMY BOTTER, ANDREA CAMMAROSANO,
          STEPHANIE D’HEYGERE, TARO HORIUCHI, MINJU KIM, RAPHAËLE LENSEIGNE,
          LINDA LOPPA, NIELS PEERAER, JURGI PERSOONS, PETER PHILIPS, SHUTING
          QIU, MIKIO SAKABE, KRIS VAN ASSCHE, JAN-JAN VAN ESSCHE, AN VANDEVORST,
          CHRISTIAN WIJNANTS, BERNHARD WILLHELM
        </div>
        <div className={styles.textGroup}>
          <span className={styles.smallText}>
            ALL PHOTOGRAPHS OF JULIA BALLARDT ARE MADE WITH THE EXCEPTIONAL
            PERMISSION OF
          </span>
          MIDDELHEIM MUSEUM ANTWERPEN
        </div>
        <div className={styles.textGroup}>
          <span className={styles.smallText}>CREDITS LUCA HOLZINGER </span>
          ‘TURNING THE PLACE OVER BY RICHARD WILSON’, 2007 BY MIYAKO NARITA,
          ‘GORDON MATTA CLARK - SPLITTING, 1974’ BY ELECTRONIC ARTS INTERMIX EAI
        </div>
        <div className={styles.textGroup}>
          <span className={styles.smallText}> FIRST YEAR BACHELOR</span>
          EMILLIANO ALVARES TORRES, JOSEPH BERTHIER, REMY BOUTAREAU, ELISE
          BRIANT DE LAUBRIERE, JACQUELINE BUSCH, BABETT JULIANE BYRTUS, RU CAI,
          NANA CHUTO, LEON COLE, YAËL COOPMAN, ISAAC DAHAN, ULIANA DOBROVSKAYA,
          MORYANA, FILIPOVA, SARA GENIN, BATISTE GODBERT, GUILLAUME GOSSEN,
          HYEJIN HA, LAURA HAGESTED MEIER, YUANJING HE / JEANETTE, ANNELEEN
          HEIRBOUT, SOFIA HERMENS FERNANDEZ, NOAH HERREMANS, JOSHUA HILL, RASMUS
          HOLM, HAYOUNG JEONG, CHORONG JU / RANHA, ANTONIA KANNEFASS, ACHRAF
          KATEFRE, HYUN JI KIM /LOHIN, JIN YOUNG KWON, ARMANCE LAGORCE, ASHTON
          LANG, LUISA LATER, JANG EUM LEE, YEJI LEE, XINYU LI, WEI LIU, DANIEL
          MASION, EMILIE MAZET, ALBA NIESSEN, YUAN PENG /PHOEBE, RASHEED
          PICKSTOCK, FRANZISKA PROHASKA, CHANG JIN REN, BRIAN ROJAS CUADROS,
          ANTONIA SELL, SIYAO SHAN, JIAYUE SHE /JILL, ROHAN STEINMEYER, TIM
          STOLTE, CHIARA STOVE , MARCEL SOMMER, LEA SWAROVSKI, POLINA
          TARASOVA,YUHEI UEDA, CHIARA VAN CLEEMPUT, NATAN VERBEELEN, ALEXANDRE
          WARUNEK, ROSA WESTHAUS, TIM WIRTH, CEZARY ZALIT,YIHUI ZANG /KARINA
        </div>
        <div className={styles.textGroup}>
          <span className={styles.smallText}> SECOND YEAR BACHELOR</span>
          IGOR DIERYCK AMIR TORRES AARON HÜTTENMEISTER ANNA GARIDI BYEONGHO LEE
          BRIAC TRÉMOLIÈRES MATTEO BECKER DAN YAO JIANG SEONGMIN KOO DOMINIKA
          GRZYBEK ANAND AMGALANBAATAR FREDERIK LOVRIC XUEWEN CHEN ISABELLE DU
          VICTORIA LEBRUN JINNY SONG JIHYUN CHUNG DADA PEIWEN MAO QIUZI JIN
          HYEJEONG LEE ELI RÉBECCA DESBORDES XERXES GABRIEL WATT ZIXIANG HE AMAR
          AMGALANBAATAR ROMANE GLANDIER ALINE VEREST VIOLETTE DES ROSEAUX
          MARIAMI TSOTADZE SHABASH COGNATA CLAIRE GABELUS NATALIA HERNANDEZ
          YONGHAO XIE YUEMING LI LUNA MORAGA-GARCIA XIN HUANG ANNA DZIRNIECE
          ALISE CHING-LIN CHEN
        </div>
        <div className={styles.textGroup}>
          <span className={styles.smallText}> THIRD YEAR BACHELOR</span>
          EZIO COSTA JASMIN BANDOMER YEONGHO KO MARC PENGEL JOHANNA VON MORL JAN
          NOVY MALENE AHLERT LIAO HONGYU INGMAR PLUSCZYK JEJUNG PARK KAYA
          GAYOUNG LEE JIYEON TAK TAEHYEOK GONG HUIZE YOU ROBERTO NICOLAS
          MARCHIARO TENDERO PHILEMON HAHLWEG JULIE KEGELS LUCA HOLZINGER ANIA
          YELIZARYEVA GIORGIA GALFRE
        </div>

        <div className={styles.textGroup}>
          <span className={styles.smallText}> MASTERS</span>
          MARIE MARTENS JULIA BALLARDT FLORENTINA LEITNER NICO VERHAEGEN SABRINA
          PFATTNER ISABELLE LEMPIK SILVIA ROGNONI KAROLINA WIDECKA ANNEMARIE
          SARIC
        </div>

        <div className={styles.textGroup}>
          <span className={styles.boldText}>
            THE STUDENTS OF THE FASHION DEPARTMENT WOULD LIKE TO THANK THEIR
            PARENTS, FAMILY AND FRIENDS AS WELL AS EVERYBODY THAT COOPERATED ON
            THEIR COLLECTIONS.
          </span>
        </div>
        <div className={styles.textGroup}>
          <span className={styles.nextLine}>
            <span className={styles.boldText}>
              THE ANTWERP FASHION DEPARTMENT WOULD LIKE TO THANK
            </span>
          </span>
          JOHAN PAS, PASCALE DE GROOTE, AP HOGESCHOOL, THE CITY OF ANTWERP, DANI
          ACHTEN, MARYSSE DANIELS AND BARBARA DE SCHEPPER FROM THE HANDELSBEURS,
          ANTON JANSSENS FROM KOMONO, ROSIER, MOMU, ANNA HEYLEN, BVBA32,
          LABELS&THINGS, KNACK WEEKEND, CATWALKPICTURES, ELLE, JUDITH GROSS FROM
          IFF, INGRID OOMEN FROM WOOLMARK, SUEDWOLLE GROUP, LIEBAERT, MOPAN
          ALKEN AND FLANDERS FASHION MAKERS, VENTURES, MATERIALISE, DRIES VAN
          NOTEN FOR THE GENEROUS DONATION OF FABRICS, PERFUME GENIUS, MIDDELHEIM
          MUSEUM ANTWERPEN, KARINE ROTSAERT, JENNY HERREMANS, LOUIZA EL QUAHDI,
          JAN VAN GEEL, GWYN JANSSENS, KAAT DEBO, CHRISTIAN WIJNANTS, PATRICK
          VAN OMMESLAEGHE AND THE WHOLE TEAM OF TEACHERS OF THE FASHION
          DEPARTMENT.
        </div>
        <div className={styles.textGroup}>
          <span className={styles.boldText}>
            SPECIAL THANKS TO NELLIE NOOREN FOR HER YEARS OF DEVOTION AS A
            TEACHER, MEMBER OF THE BOARD AND COORDINATOR OF THE FASHION
            DEPARTMENT.
          </span>
        </div>
        <div className={styles.textGroup}>
          <span className={styles.smallText}>
            All photos and videos on www.showww.be are copyrighted. No parts of
            the content of this website may be reproduced or distributed,
            without the prior written permission of the Royal Academy of Fine
            Arts Antwerp. Deeplinking is possible, but without our content
            appearing in a new frame on your website. Without prior written
            permission it is not permitted to copy, download, or reproduce these
            images in any way whatsoever. If you wish to copy texts, photos or
            videos in any other way, please send an e-mail to
            communicatie.academie@ap.be
          </span>
        </div>
      </div>
      <div className={styles.formContainer}>
        <form
          onSubmit={handleSubmit}
          aria-label="subscribe form"
          // className={styles.bottomLeft}
        >
          {isSuccess ? (
            <>
              <div className={styles.textGroup}>
                <div>WWWELCOME! Thank you for signing up.</div>
                <img className={styles.successTick} alt="success" src={Tick} />
              </div>
            </>
          ) : (
            <div className={styles.CTA}>
              <div>Stay in Touch</div>

              <input
                aria-label="input email"
                placeholder="Enter Your Email"
                value={email}
                type="text"
                className={styles.inputStyles}
                onChange={handleEmailChange}
              />

              <button
                className={styles.buttonStyles}
                alt="submit"
                type="submit"
              >
                Submit
              </button>

              {error && (
                <div>
                  <p>Something went wrong. Try again.</p>
                </div>
              )}
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default Credits;
