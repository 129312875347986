import {
  Engine,
  Vector3,
  HemisphericLight,
  SceneLoader,
  MeshBuilder,
  Color3,
  Color4,
  Scene,
  CubeTexture,
  Material,
} from '@babylonjs/core';
import runwaySpline from './curve.json';

const initializeBabylon = async () => {
  const hiddenCanvasThatBabylonNeedsToNotThrowErrors = document.createElement(
    'canvas',
  );
  const engine = new Engine(hiddenCanvasThatBabylonNeedsToNotThrowErrors);

  const scene = new Scene(engine);
  scene.clearColor = new Color4(0, 0, 0, 0);

  const light = new HemisphericLight('light', new Vector3(0, 1, 0), scene);
  light.intensity = 1;

  // Load cameras from .babylon file
  const camerasAssetContainer = await SceneLoader.LoadAssetContainerAsync(
    '/models/',
    'finalCameras.babylon',
    scene,
  );
  camerasAssetContainer.addAllToScene();

  const runwayPoints = runwaySpline.curves[0].points.map(
    ({ coord }) => new Vector3(coord[0], coord[2], coord[1]),
  );

  const runwayTrack = MeshBuilder.CreateLines(
    'runwayTrack',
    {
      points: runwayPoints,
    },
    scene,
  );

  runwayTrack.visibility = 0;

  return { engine, scene, runwayPoints };
};

export default initializeBabylon;
