import React from 'react';
import classNames from 'classnames';
import { navigate } from 'gatsby';
import styles from './InterfaceText.module.css';
import AudioOff from '../../../static/img/icons/AudioOff.png';
import AudioOn from '../../../static/img/icons/AudioOn.svg';
import cameraOff from '../../../static/img/icons/cameraOff.svg';
import cameraOn from '../../../static/img/icons/cameraOn.svg';

const InterfaceText = ({
  scene,
  isCuratedViewOn,
  isMuted,
  isShowingCredits,
  onToggleMute,
  onClickShow,
  onClickCredits,
  onToggleCuratedView,
}) => {
  return (
    <div className={classNames(styles.interfaceText)}>
      <div className={styles.topLeft}>
        <button
          className={classNames(styles.button, {
            [styles.isActive]: !isShowingCredits,
          })}
          onClick={() => {
            onClickShow();
            if (isCuratedViewOn) {
              onToggleCuratedView(false);
            }
          }}
        >
          Show
        </button>
        <button
          className={classNames(styles.button, {
            [styles.isActive]: isShowingCredits,
          })}
          onClick={() => {
            onClickCredits();
            if (isCuratedViewOn) {
              onToggleCuratedView(false);
            }
          }}
        >
          Credits
        </button>
      </div>
      <div className={styles.topRight}>
        <button
          className={classNames(styles.interactButton)}
          onClick={() => {
            if (isCuratedViewOn) {
              navigate('/');
            }
            onToggleCuratedView(!isCuratedViewOn);
          }}
        >
          <img
            src={isCuratedViewOn ? cameraOn : cameraOff}
            className={styles.curatedIcon}
            alt=""
          />
          <span>Curated View</span>
        </button>
        <button className={styles.interactButton} onClick={onToggleMute}>
          <img
            src={isMuted ? AudioOff : AudioOn}
            className={styles.audioIcon}
            alt=""
          />
          <span>Audio {isMuted ? 'off' : 'on'}</span>
        </button>
      </div>
      <div
        className={classNames(styles.bottomLeft, {
          [styles.hide]: isShowingCredits,
        })}
      >
        {scene.title}
      </div>

      <div
        className={classNames(styles.bottomRight, {
          [styles.hide]: isShowingCredits,
        })}
      >
        Antwerp, BE
      </div>
    </div>
  );
};

export default InterfaceText;
