import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import ReactPlayer from 'react-player';

import styles from './Video.module.css';

const backgroundVideos = [
  'https://player.vimeo.com/external/432978196.m3u8?s=1a92b5f158644c924f86ed3f31740a1fdab7518a', // A
  'https://player.vimeo.com/external/432981531.m3u8?s=d214aeaa81a7e233b6b8b39df3b80271f6a5b19d', // C
  'https://player.vimeo.com/external/433127107.m3u8?s=b68cc7fa356f9fdaea661f51d5e5c204fd54904e', // E
  'https://player.vimeo.com/external/433019225.m3u8?s=2ea89b0a5601ceb85f989267c28fe3854c630035', // F
];

const Video = ({
  cameraIndex,
  isLarge,
  isMuted,
  isPlaying,
  isVisible,
  timeRef,
}) => {
  const videoRef = useRef();
  const [isBuffering, setIsBuffering] = useState(false);

  useEffect(() => {
    if (!isVisible) {
      return;
    }
    const syncingThreshold = 0.5;
    const seekAheadOffsetWhenPlaying = 0.1;
    let checkIntervalId = setInterval(() => {
      const playerTime = videoRef.current.getCurrentTime();
      if (Math.abs(playerTime - timeRef.current) > syncingThreshold) {
        setIsBuffering(true);
        videoRef.current.seekTo(timeRef.current);
      } else {
        setIsBuffering(false);
      }
    }, syncingThreshold * 1000);

    return () => {
      clearInterval(checkIntervalId);
    };
  }, [isPlaying, isVisible]);

  return (
    <div
      className={classNames(styles.video, {
        [styles.isBuffering]: isBuffering,
      })}
    >
      <ReactPlayer
        loop
        muted={isMuted || (!isLarge && cameraIndex !== 0) || !isVisible}
        playing={isPlaying}
        playsinline
        pip={false}
        controls={false}
        ref={videoRef}
        url={backgroundVideos[cameraIndex]}
        height="100%"
        width="100%"
        config={{
          file: {
            // Improve performance but reduce quality
            hlsOptions: {
              capLevelToPlayerSize: true,
              capLevelOnFPSDrop: true,
              startLevel: 0,
            },
          },
        }}
      />
    </div>
  );
};

export default Video;
