export const getCameraModelFocus = (
  progressA,
  progressB,
  entryRange,
  exitRange,
  getClosestToExit,
) => {
  // Always check the progress of current if it exists
  if (
    progressA &&
    progressA.progress >= entryRange &&
    progressA.progress <= exitRange
  ) {
    // To prevent returning b in case it is out of range
    if (progressB.progress >= entryRange && progressB.progress <= exitRange) {
      return progressB.progress > progressA.progress && getClosestToExit
        ? progressB
        : progressA;
    }
    return progressA;
    // If a does not exist and the model is in range. SET
    // Only on the first iteration
  } else if (
    progressB.progress >= entryRange &&
    progressB.progress <= exitRange
  ) {
    return progressB;
  }
};
