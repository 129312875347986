import React, {
  useEffect,
  useMemo,
  useState,
  useLayoutEffect,
  useRef,
} from 'react';
import styles from './ProgressBar.module.css';

import useRunEveryFrame from '../../../utils/useRunEveryFrame';

const ProgressBar = ({
  onUpdateIsPlaying,
  onUpdateTime,
  timeline,
  timeRef,
}) => {
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const [wasPlayingOnStartDrag, setWasPlayingOnStartDrag] = useState(true);

  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    function getSize() {
      if (targetRef.current) {
        setDimensions({
          width: targetRef.current.offsetWidth,
          height: targetRef.current.offsetHeight,
        });
      }
    }

    window.addEventListener('resize', getSize);
    return () => window.removeEventListener('resize', getSize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  useEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
      });
    }
  }, [targetRef.current]);

  useRunEveryFrame(() => {
    setCurrentTime(timeRef.current);
  });

  useEffect(() => onUpdateIsPlaying(isPlaying), [isPlaying, onUpdateIsPlaying]);

  const [isDragging, setIsDragging] = useState(false);

  const updateTimeByEvent = e => {
    const {
      x: elementX,
      width: elementWidth,
    } = e.currentTarget.getBoundingClientRect();
    const progress = (e.clientX - elementX) / elementWidth;
    onUpdateTime(Math.max(0, Math.min(1, progress)) * timeline.totalDuration);
  };

  const handleDrag = e => {
    if (isDragging) {
      updateTimeByEvent(e);
    }
  };

  const handleStartDrag = e => {
    setIsDragging(true);
    setWasPlayingOnStartDrag(isPlaying);
    setIsPlaying(false);
    updateTimeByEvent(e);
  };

  const handleEndDrag = () => {
    setIsDragging(false);
    setIsPlaying(wasPlayingOnStartDrag);
  };

  const timelineDOM = useMemo(
    () =>
      timeline.scenes.map((scene, sceneIndex) => {
        return (
          <div
            key={`${sceneIndex}-${scene}`}
            className={styles.marker}
            style={{
              left: `${
                ((scene.startTime + scene.duration) / timeline.totalDuration) *
                100
              }%`,
            }}
          />
        );
      }),
    [timeline],
  );

  return (
    <div className={styles.wrapper}>
      <button
        className={styles.playPause}
        onClick={() => {
          setIsPlaying(wasPlayingOnClick => !wasPlayingOnClick);
        }}
      >
        <span>
          {isPlaying ? (
            <img src="/img/icons/Pause.svg" alt="Pause button" />
          ) : (
            <img src="/img/icons/Play.svg" alt="Play button" />
          )}
        </span>
      </button>
      <div
        className={styles.bar}
        onPointerDown={handleStartDrag}
        onPointerMove={handleDrag}
        onPointerUp={handleEndDrag}
        onPointerCancel={handleEndDrag}
        ref={targetRef}
      >
        <div
          className={styles.progress}
          style={{
            transform: `translate(${
              (currentTime / timeline.totalDuration) * dimensions.width
            }px)`,
          }}
        />
        {timelineDOM}
      </div>
    </div>
  );
};

export default ProgressBar;
