import {
  SceneLoader,
  Color3,
  StandardMaterial,
  Texture,
  VideoTexture,
  Vector3,
  MeshBuilder,
} from '@babylonjs/core';
import rotationsByMeshName from './rotationsByMeshName';

import '@babylonjs/core/Meshes/meshBuilder';
import '@babylonjs/loaders/glTF';

export const loadModel = async (scene, fileName) => {
  const container = await SceneLoader.LoadAssetContainerAsync(
    'https://s3.eu-central-1.amazonaws.com/showww.be/glbs/',
    fileName,
    scene,
  );

  const modelMesh = container.meshes[0];
  modelMesh.name = fileName;
  const modelSkeleton = container.skeletons[0];

  // Parent the skeleton to group it in modelMesh
  modelSkeleton.parent = modelMesh;

  return container;
};

export const createModel = ({
  isTextured,
  scene,
  modelDefinition,
  template,
  templateType,
}) => {
  const frontAsset = modelDefinition.modelAssets.frontAsset;
  const backAsset = modelDefinition.modelAssets.backAsset;

  const newModel = template.instantiateModelsToScene();
  const mesh = newModel.rootNodes[0];
  let meshMaterial;

  if (isTextured) {
    meshMaterial = addMaterialToModelMesh({
      mesh: mesh.getChildMeshes()[0],
      asset: frontAsset,
      scene: scene,
    });
  }

  // Refer back to the base skeleton for the form
  const modelSkeleton = newModel.skeletons[0];

  // Attach to the hip unless you're of type a, then attach to the left shoulder
  const assetAttachBoneIndex = templateType === 'a' ? 10 : 5;

  let frontPlane = createImagePlaneMaterial({
    scene,
    src: frontAsset.src,
    assetType: frontAsset.assetType,
    attachTo: modelSkeleton.bones[assetAttachBoneIndex],
    mesh,
    isBack: false,
  });

  let backPlane = createImagePlaneMaterial({
    scene,
    src: backAsset.src,
    assetType: backAsset.assetType,
    attachTo: modelSkeleton.bones[assetAttachBoneIndex],
    mesh,
    isBack: true,
  });

  mesh.setEnabled(false);
  frontPlane.setEnabled(false);
  backPlane.setEnabled(false);

  mesh.doNotSyncBoundingInfo = true;
  frontPlane.doNotSyncBoundingInfo = true;
  backPlane.doNotSyncBoundingInfo = true;

  return {
    wasDisabled: true,
    modelDefinition,
    mesh,
    animation: newModel.animationGroups[0],
    skeleton: modelSkeleton,
    backPlane,
    frontPlane,
    meshMaterial,
  };
};

export const createImagePlaneMaterial = ({
  scene,
  src,
  assetType,
  attachTo,
  mesh,
  isBack,
}) => {
  const baseHeight = 2;

  switch (assetType) {
    case 'IMAGE':
    case 'PNG':
      const imageMaterial = new StandardMaterial('image_material', scene);
      const imageTexture = new Texture(src, scene);
      imageMaterial.emissiveColor = new Color3(1, 1, 1); // self-illuminate

      const imagePlane = createImagePlane({
        scene,
        attachTo,
        isBack,
        material: imageMaterial,
        mesh,
      });

      imageTexture.onLoadObservable.addOnce(loadedTexture => {
        imageMaterial.diffuseTexture = loadedTexture;
        imageMaterial.diffuseTexture.uScale = -1;
        if (assetType === 'PNG') {
          imageMaterial.opacityTexture = loadedTexture;
        }

        const { height, width } = imageMaterial.diffuseTexture.getSize();
        const newWidth = (width / height) * baseHeight;

        imagePlane.scaling.x = newWidth * imagePlane.scaling.x;
      });

      return imagePlane;

    case 'VIDEO':
      const videoMaterial = new StandardMaterial('video_material', scene);
      const videoTexture = new VideoTexture('vid', src, scene, true);
      videoTexture.video.muted = true;
      videoTexture.video.play();

      videoMaterial.emissiveColor = new Color3(1, 1, 1);

      const videoPlane = createImagePlane({
        scene,
        attachTo,
        isBack,
        material: videoMaterial,
        mesh,
      });

      videoTexture.onLoadObservable.addOnce(loadedTexture => {
        videoMaterial.diffuseTexture = loadedTexture;
        videoMaterial.diffuseTexture.uScale = -1;

        const { height, width } = videoMaterial.diffuseTexture.getSize();
        const newWidth = (width / height) * baseHeight;

        videoPlane.scaling.x = newWidth * videoPlane.scaling.x;
      });

      return videoPlane;

    default:
      break;
  }
};

export const createImagePlane = ({
  scene,
  attachTo,
  isBack,
  material,
  mesh,
}) => {
  let imagePlane = MeshBuilder.CreatePlane(
    'imagePlane',
    { width: 1, height: 2 },
    scene,
    true,
    MeshBuilder.FRONTSIDE,
  );

  const positionAndRotationForMesh = rotationsByMeshName[mesh.name];

  if (positionAndRotationForMesh) {
    const positionAndRotationForPlane =
      positionAndRotationForMesh[isBack ? 'back' : 'front'];
    if (positionAndRotationForPlane.rotationX) {
      imagePlane.rotation.x = positionAndRotationForPlane.rotationX;
    }
    if (positionAndRotationForPlane.rotationZ) {
      imagePlane.rotation.z = positionAndRotationForPlane.rotationZ;
    }
    if (positionAndRotationForPlane.position) {
      imagePlane.position = positionAndRotationForPlane.position;
    }
    if (!isBack) {
      // Rotate backwards
      imagePlane.rotation.y = Math.PI;
    }
  } else {
    if (isBack) {
      imagePlane.position = new Vector3(0, 0, -25);
    } else {
      imagePlane.position = new Vector3(0, 0, 25);
      imagePlane.rotation.y = Math.PI;
    }
  }

  imagePlane.material = material;

  imagePlane.attachToBone(attachTo, mesh);
  imagePlane.scaling = new Vector3(150, 150, 150);

  return imagePlane;
};

export const addMaterialToModelMesh = ({ mesh, asset, scene }) => {
  const imageMaterial = new StandardMaterial('image_material', scene);
  const imageTexture = new Texture(asset.src, scene);

  imageTexture.onLoadObservable.addOnce(loadedTexture => {
    imageMaterial.diffuseTexture = loadedTexture;
    mesh.material = imageMaterial;
  });

  return imageMaterial;
};
