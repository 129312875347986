import '../styles/global.css';
import '../styles/reset.css';

import React from 'react';
import Holding from '../components/holding';
import Show from '../components/Show/Show';
import SEO from '../components/SEO';
import useTimeAfterShowStart from '../utils/useTimeAfterShowStart';
import TIMELINE from '../../nodeScript/MANUAL_MASTER_TIMELINE.json';
import parseTimeline from '../utils/parseTimeline';
const parsedTimeline = parseTimeline(TIMELINE);

// Show holding page if has not started yet, show otherwise
export default ({ location }) => {
  const query = new URLSearchParams(location.search);
  const activeCamera = query.get('camera');
  const isShowingCredits = query.get('view') === 'credits';
  const announcedTimeAfterShowStart = useTimeAfterShowStart();

  if (announcedTimeAfterShowStart === null) {
    return null;
  }

  // First scene is interviews, which start before the announced start time
  const interviewTotalDuration = parsedTimeline.scenes[0].duration * 1000;
  const actualTimeAfterShowStart =
    announcedTimeAfterShowStart + interviewTotalDuration;
  const showDuration = parsedTimeline.totalDuration * 1000;

  const isBeforeLiveShow = actualTimeAfterShowStart < 0;
  const isDuringLiveShow =
    actualTimeAfterShowStart >= 0 && actualTimeAfterShowStart < showDuration;

  return (
    <>
      <SEO />
      {isBeforeLiveShow ? (
        <Holding />
      ) : (
        <Show
          activeCamera={activeCamera}
          isShowingCredits={isShowingCredits}
          isLive={isDuringLiveShow}
          parsedTimeline={parsedTimeline}
        />
      )}
    </>
  );
};
